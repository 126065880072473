import { ErrorBox } from 'components/ErrorBox';
import { SuccessBox } from 'components/SuccessBox';
import { ValidationError } from 'components/ValidationError';
import { emailValidator } from 'helpers/validators';
import React, { useState } from 'react';
import { forgotPassword } from 'services/Api';

export function ForgotPassword() {

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<string>('');

	function onSubmit() {

		validateEmail(email);

		forgotPassword(email)
			.then(() => setSuccess(`We've sent you a password reset email. If you don't receive it, double check you have an account with this email address`))
			.catch(error => setError(error.message));
	}

	function onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {

		validateEmail(event.target.value);

		setEmail(event.target.value);
	}

	function validateEmail(email: string) {

		const { error } = emailValidator.validate(email);

		if (error) {
			setEmailError(error.message);
			return false;
		} else {
			setEmailError('');
			return true;
		}
	}

	return (
		<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<img className="mx-auto max-h-32 w-auto" src="/images/logo.png" alt="Mascot" />
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
					Forgot Your Password?
				</h2>
			</div>

			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<ErrorBox error={error}></ErrorBox>
					<SuccessBox error={success}></SuccessBox>
					<form className="space-y-6" action="#" method="POST">
						<div>
							<label htmlFor="email" className="block text-sm font-medium text-gray-700">
								Email Address
							</label>
							<div className="mt-1">
								<input value={email} onChange={onEmailChange} name="email" type="email" autoComplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
								<ValidationError error={emailError}></ValidationError>
							</div>
						</div>

						<div>
							<button onClick={onSubmit} type="button" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
								Reset Password
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
