import React from 'react';
import {
	Link, NavLink, Outlet
} from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

export const InternalLayout = () => {

	const breadcrumbs = useBreadcrumbs();

	// useEffect(() => {
	// 	if (!isLoggedIn()) {
	// 		navigate('/login');
	// 	}
	// }, [])

	return (
		<div>
			<nav className="bg-gray-800">
				<div className="w-100 px-2 sm:px-6 lg:px-8">
					<div className="relative flex items-center justify-between h-16">
						<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
							<button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
								<span className="sr-only">Open main menu</span>


							</button>
						</div>
						<div className="flex-1 flex items-center sm:items-stretch justify-start">
							<NavLink to="/">
								<img className="block mb-2 h-8 w-auto ml-2" src="/images/logo-text.png" alt="Mascot" />
							</NavLink>
							<div className="hidden sm:block sm:ml-6">
								<div className="flex space-x-4">
									<NavLink to="weight" className={({ isActive }) => (isActive ? 'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium' : 'text-white px-3 py-2 rounded-md text-sm font-medium')}>Weight</NavLink>
									<NavLink to="water" className={({ isActive }) => (isActive ? 'bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium' : 'text-white px-3 py-2 rounded-md text-sm font-medium')}>Water</NavLink>
								</div>
							</div>
						</div>
						<div className="flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
							<div className="ml-3 relative">
								<div>
									<button type="button" className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu-button" aria-expanded="false" aria-haspopup="true">

									</button>
									<Menu as="div" className="z-50 relative inline-block text-left">
										<div>
											<Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
												<FontAwesomeIcon icon={['far', 'gear']} className="mr-2 text-white text-3xl" />
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
												<div className="px-1 py-1 ">
													<Menu.Item>
														{({ active }) => (
															<NavLink to="settings" className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'
																} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Settings</NavLink>
														)}
													</Menu.Item>
													<Menu.Item>
														{({ active }) => (
															<NavLink to="logout" className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'
																} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>Log Out</NavLink>
														)}
													</Menu.Item>
												</div>
											</Menu.Items>
										</Transition>
									</Menu>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="sm:hidden" id="mobile-menu">
					<div className="px-2 pt-2 pb-3 space-y-1">
						<Link to="weight" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Weight</Link>
						<Link to="water" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Water</Link>
					</div>
				</div>
			</nav>

			<div>

				<nav className="bg-white border-b border-gray-200 flex" aria-label="Breadcrumb">
					<ol className="max-w-screen-xl w-full flex space-x-4">


						<React.Fragment>
							{breadcrumbs.map(({ match, breadcrumb }, index) =>

								<li className="flex" key={index}>
									<div className="flex items-center">
										<svg className="flex-shrink-0 w-6 h-full text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
											<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
										</svg>
										<Link to={match.pathname} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{breadcrumb}</Link>
									</div>
								</li>
							)}
						</React.Fragment>
					</ol>
				</nav>

				<div className="">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
