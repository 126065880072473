import { isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { LineChart } from "../../components/charts/LineChart";
import { Weight } from '../../models/weight';
import { getWeights } from '../../services/Api';
import { critical } from '../../services/Notifications';
import { WeightsListComponent } from './WeightsList';
import { CurrentWeightWidget } from './CurrentWeightWidget';
import { NoWeights } from './NoWeights';
import { sortDesc } from 'models/date-object';

export const WeightComponent = () => {

	const [weights, setWeights] = useState([] as Weight[]);

	useEffect(() => {
		getWeights()
			.then(weights => setWeights(weights.sort(sortDesc)))
			.catch((e) => critical(e));
	}, []);

	async function addWeight(weight: Weight) {
		setWeights([...weights, weight].sort(sortDesc));
	}

	async function onEdit(weight: Weight) {
		setWeights(weights.map(w => isSameDay(w.date, weight.date) ? weight : w).sort(sortDesc));
	}

	async function onDelete(weight: Weight) {
		setWeights(weights.filter(w => w.date !== weight.date));
	}

	function hasWeights() {
		return weights.length > 0;
	}

	return (

		<div>
			{!hasWeights() && <NoWeights onEnterWeight={addWeight}></NoWeights>}
			{hasWeights() &&
				<div className="p-8 flex justify-between w-full h-screen-without-header overflow-y-scroll">
					<div className="w-2/4 mr-8 md:w-full">
						<div className="flex flex-col">
							<CurrentWeightWidget weights={weights} onEnterWeight={weight => addWeight(weight)}></CurrentWeightWidget>
							<WeightsListComponent
								weights={weights}
								onEdit={onEdit}
								onDelete={onDelete}
							></WeightsListComponent>
						</div>
					</div>
					<div className="w-2/4 md:w-full h-screen-without-header">
						<LineChart data={weights} ></LineChart>
					</div>
				</div>}
		</div>
	)
}
