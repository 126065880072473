import { useEffect, useState } from "react"

export function ValidationError(props: { error: string }) {

	const [error, setError] = useState(props.error)

	useEffect(() => setError(props.error), [props.error])

	return (
		<div>
			{error && <p className="text-sm text-red-600">{error}</p>}
		</div>
	)
}
