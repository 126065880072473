import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, getUnixTime } from 'date-fns';
import { sortDesc } from 'models/date-object';
import { Water } from 'models/water';
import { useEffect, useState } from 'react';
import { updateWater, deleteWater } from 'services/Api';
import { promptForDateAndAmount, showDeleteConfirmationPrompt } from 'services/Notifications';
import { formatLongDate, timeago } from '../../helpers/date';
import { Weight } from '../../models/weight';
import ReactTooltip from 'react-tooltip';

export const WatersList = (props: { waters: Weight[], onEdit: (water: Water) => void, onDelete: (water: Water) => void }) => {

	const [waters, setWaters] = useState(props.waters);
	useEffect(() => {
		setWaters(props.waters);
		ReactTooltip.rebuild();
	}, [props.waters]);

	async function onEdit(water: Water) {

		const prompts = await promptForDateAndAmount('Please enter the amount of water', false, water.amount, water.date);

		if (!prompts) {
			return;
		}

		const updatedWater = await updateWater(prompts.date, prompts.amount);

		props.onEdit(updatedWater)
	}

	async function onDelete(water: Water) {

		const confirm = await showDeleteConfirmationPrompt('Delete Your Water?', `Are you sure you want to delete your water for ${format(water.date, 'EEEE')}?`);

		if (!confirm) {
			return;
		}

		await deleteWater(water);

		props.onDelete(water);
	}

	function getIcon(water: Water): [IconPrefix, IconName] {

		if (water.amount <= 32) {
			return ['far', 'glass'];
		}

		if (water.amount <= 64) {
			return ['fas', 'glass-half'];
		}

		if (water.amount < 128) {
			return ['fas', 'glass'];
		}

		if (water.amount >= 128) {
			return ['far', 'thumbs-up']
		}

		return ['far', 'thumbs-up'];
	}

	return (
		<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
			<table className="min-w-full divide-y divide-gray-200">
				<thead className="bg-gray-200">
					<tr>
						<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Date
						</th>
						<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
							Amount
						</th>
						<th scope="col" className="relative px-6 py-3">
							<span className="sr-only">Edit</span>
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{
						waters.sort(sortDesc).map(water => {

							return (
								<tr key={getUnixTime(water.date)}>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div className="flex-shrink-0 h-10 w-10">
												<FontAwesomeIcon icon={getIcon(water)} className="text-4xl" />
											</div>
											<div className="ml-4">
												<div className="text-sm font-medium text-gray-900">
													{formatLongDate(water.date)}
												</div>
												<div className="text-sm text-gray-500">
													{timeago(water.date)}
												</div>
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">{water.amount}oz</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
										<button data-tip={"Edit Amount"} onClick={() => onEdit(water)} className="text-gray-500 mr-5"><FontAwesomeIcon icon={['far', 'pencil']} className="text-xl" /></button>
										<button data-tip={`Delete ${format(water.date, 'EEEE')}`} onClick={() => onDelete(water)} className="text-gray-500"><FontAwesomeIcon icon={['far', 'trash-can']} className="text-xl" /></button>
									</td>
								</tr>
							)
						})
					}
				</tbody>
			</table>
		</div>

	)
}
