import { isFuture } from 'date-fns';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { formatIsoDate, getTodayAsIsoDate, parseIsoDate } from '../helpers/date';
import { DateAmountPrompt } from './DateAmountPrompt';

const MySwal = withReactContent(Swal)

export function prompt(options: SweetAlertOptions) {
	return Swal.fire(options).then(result => result.value);
}

export async function promptForDateAndAmount(title: string, showDate: boolean, amount: number | undefined = undefined, date: Date | undefined = undefined) {

	let selectedDate = date ? formatIsoDate(date) : getTodayAsIsoDate();

	const result = await MySwal.fire({
		title,
		html: <DateAmountPrompt showDate={showDate} amount={amount} date={selectedDate} onChange={(d) => selectedDate = d}></DateAmountPrompt>,
		stopKeydownPropagation: false,
		preConfirm: () => {

			const amountElement = document.getElementById('prompt-amount') as HTMLInputElement;
			const amount = +amountElement.value;

			let date = selectedDate;

			if (showDate) {
				const dateElement = document.getElementById('prompt-date') as HTMLInputElement;
				date = dateElement.value;
			}

			if (isFuture(parseIsoDate(date))) {

				console.log('is future');

				Swal.showValidationMessage(`The date can't be in the future`);

				return;
			}

			return {
				amount: amount,
				date: selectedDate
			}
		}
	});

	return result.value;
}

export function showDeleteConfirmationPrompt(title: string, message: string) {
	return MySwal.fire({
		title,
		text: message,
		icon: 'question',
		confirmButtonText: 'Delete',
		showCancelButton: true,
		// tslint:disable:no-console
	}).then(result => result.value);
}

export function success(message: string) {
	toast.success(message, {
		position: toast.POSITION.TOP_RIGHT
	});
}

export function critical(error: Error) {

	console.error(error);

	return MySwal.fire({
		title: 'We\'re Sorry',
		text: 'There was a problem. We`re not sure what happened :(',
		icon: 'error',
		confirmButtonText: 'OK'
		// tslint:disable:no-console
	});
}
