import { compareAsc, compareDesc } from 'date-fns';

export interface DateObject {
	readonly amount: number;
	readonly date: Date;
}

export interface DateStringObject {
	readonly amount: number;
	readonly date: string;
}

export interface DateMaybeObject {
	readonly amount: number | null;
	readonly date: Date;
	readonly week?: number | string;
	readonly weekday?: string;
}

export const sortAsc = (a: DateMaybeObject, b: DateMaybeObject) => compareAsc(a.date, b.date);
export const sortDesc = (a: DateMaybeObject, b: DateMaybeObject) => compareDesc(a.date, b.date);
