import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Weight } from '../../models/weight';
import { addOrUpdateWeight } from '../../services/Api';
import { promptForDateAndAmount } from '../../services/Notifications';

export const NoWeights = (props: { onEnterWeight: (weight: Weight) => void }) => {

	async function enterWeight() {

		const prompts = await promptForDateAndAmount('Enter your weight', true);

		if (!prompts) {
			return;
		}

		console.log(prompts);

		const weight = await addOrUpdateWeight(prompts.date, prompts.amount);

		props.onEnterWeight(weight);
	}

	return (
		<div className="flex justify-center items-center h-screen-without-header">
			<div className="text-center">
				<FontAwesomeIcon icon={['far', 'weight']} className="mr-2 text-5xl" />
				<h3 className="mt-2 text-sm font-medium text-gray-900">No weights</h3>
				<p className="mt-1 text-sm text-gray-500">Get started by logging your weight.</p>
				<div className="mt-6">
					<button onClick={enterWeight} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<FontAwesomeIcon icon={['far', 'weight']} className="mr-2" />
						Log Your Weight
					</button>
				</div>
			</div>
		</div>
	)
}
