import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isSameDay } from "date-fns";
import { useEffect, useState } from "react";
import { addWater } from "services/Api";
import { promptForDateAndAmount } from "services/Notifications";
import { getToday } from "../../helpers/date";
import { Water } from "../../models/water";

export function TodaysWaterWidget(props: { waters: Water[], onAdd: (water: Water) => void }) {

	const [waters, setWaters] = useState(props.waters);
	useEffect(() => setWaters(props.waters), [props.waters]);

	const [current, setCurrent] = useState('0oz');

	useEffect(() => {

		const today = getToday();
		const todaysWater = waters.find(w => isSameDay(w.date, today));

		if (todaysWater) {
			setCurrent(`${todaysWater.amount}oz`);
		}

	}, [waters])

	async function onAdd() {
		const prompts = await promptForDateAndAmount('Enter Water', true);

		if (!prompts) {
			return;
		}

		const water = await addWater(prompts.date, prompts.amount);

		props.onAdd(water);
	}

	return (

		<div>
			<div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
				<dt>
					<div className="absolute rounded-md p-3">
						<FontAwesomeIcon icon={['fas', 'glass']} className="text-2xl" />
					</div>
					<p className="ml-16 text-sm font-medium text-gray-500 truncate">Today's Water</p>
				</dt>
				<dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
					<p className="text-2xl font-semibold text-gray-900">{current}</p>
					<p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">


					</p>
					<div className="absolute bottom-0 inset-x-0 bg-gray-100 px-4 py-4 sm:px-6">
						<div className="text-sm">
							<button onClick={onAdd} type="button" className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
								<FontAwesomeIcon icon={['fas', 'glass']} className="mr-2" />
								Enter Water
							</button>

						</div>
					</div>
				</dd>
			</div>
		</div>

		// <div className="flex flex-col">

		// 	<button onClick={props.onAdd} className="inline-flex items-center mb-5 px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
		// 		<FontAwesomeIcon icon={['far', 'weight']} className="mr-2" />
		// 		Log Your Water
		// 	</button>

		// 	<div className="mb-5 gap-5 w-full">
		// 		<div className="bg-white shadow rounded-lg px-4 py-5 sm:p-6">
		// 			<dt className="text-base font-normal text-gray-900">
		// 				Today's Water
		// 			</dt>
		// 			<dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
		// 				<div className="flex items-baseline text-2xl font-semibold text-indigo-600">
		// 					{current}

		// 				</div>
		// 				<div className={"inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 " + (remaining === '0oz' ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800")}>
		// 					<span className="sr-only">
		// 						Remaining
		// 					</span>
		// 					{remaining}
		// 				</div>
		// 			</dd>
		// 		</div>

		// 	</div>

		// 	<div className="rounded-lg shadow overflow-hidden divide-y divide-gray-200">
		// 		<section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
		// 			<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
		// 				<h2 id="timeline-title" className="text-lg font-medium text-gray-900">Statistics</h2>
		// 				<div className="mt-6 flow-root">
		// 					<ul className="-mb-8">
		// 						<li>
		// 							<div className="relative pb-8">
		// 								<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
		// 								<div className="relative flex space-x-3">
		// 									<div>
		// 										<span className="h-8 w-8 rounded-full bg-blue-400 flex items-center justify-center ring-8 ring-white">
		// 											<FontAwesomeIcon icon={['far', 'calendar-week']} className="text-white" />
		// 										</span>
		// 									</div>
		// 									<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
		// 										<div>
		// 											<p className="text-sm text-gray-500">Average Daily Water (7 Days)</p>
		// 										</div>
		// 										<div className="text-right text-sm whitespace-nowrap text-gray-500">
		// 											{sevenDaysAvg}
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</div>
		// 						</li>
		// 						<li>
		// 							<div className="relative pb-8">
		// 								<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
		// 								<div className="relative flex space-x-3">
		// 									<div>
		// 										<span className="h-8 w-8 rounded-full bg-blue-400 flex items-center justify-center ring-8 ring-white">
		// 											<FontAwesomeIcon icon={['far', 'calendar-alt']} className="text-white" />
		// 										</span>
		// 									</div>
		// 									<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
		// 										<div>
		// 											<p className="text-sm text-gray-500">Average Daily Water (30 Days)</p>
		// 										</div>
		// 										<div className="text-right text-sm whitespace-nowrap text-gray-500">
		// 											{thirtyDaysAvg}
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</div>
		// 						</li>
		// 						<li>
		// 							<div className="relative pb-8">
		// 								<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
		// 								<div className="relative flex space-x-3">
		// 									<div>
		// 										<span className="h-8 w-8 rounded-full bg-blue-400 flex items-center justify-center ring-8 ring-white">
		// 											<FontAwesomeIcon icon={['fas', 'glass']} className="text-white" />
		// 										</span>
		// 									</div>
		// 									<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
		// 										<div>
		// 											<p className="text-sm text-gray-500">Total Water</p>
		// 										</div>
		// 										<div className="text-right text-sm whitespace-nowrap text-gray-500">
		// 											<p>{totalWater}</p>
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</div>
		// 						</li>
		// 						{/* <li>
		// 								<div className="relative pb-8">
		// 									<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
		// 									<div className="relative flex space-x-3">
		// 										<div>
		// 											<span className="h-8 w-8 rounded-full bg-blue-400 flex items-center justify-center ring-8 ring-white">
		// 												<FontAwesomeIcon icon={['far', 'flag-checkered']} className="text-white" />
		// 											</span>
		// 										</div>
		// 										<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
		// 											<div>
		// 												<p className="text-sm text-gray-500">Longest Fully Hydrated Streak</p>
		// 											</div>
		// 											<div className="text-right text-sm whitespace-nowrap text-gray-500">
		// 												<p>3 Days</p>
		// 											</div>
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</li>
		// 							<li>
		// 								<div className="relative pb-8">
		// 									<div className="relative flex space-x-3">
		// 										<div>
		// 											<span className="h-8 w-8 rounded-full bg-blue-400 flex items-center justify-center ring-8 ring-white">
		// 												<FontAwesomeIcon icon={['far', 'chart-line']} className="text-white" />
		// 											</span>
		// 										</div>
		// 										<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
		// 											<div>
		// 												<p className="text-sm text-gray-500">Most Amount of Water on a Day</p>
		// 											</div>
		// 											<div className="text-right text-sm whitespace-nowrap text-gray-500">
		// 												<p>130oz on Oct 4</p>
		// 											</div>
		// 										</div>
		// 									</div>
		// 								</div>
		// 							</li> */}
		// 					</ul>
		// 				</div>
		// 			</div>
		// 		</section>


		// 	</div>



		// </div>
	)
}
