import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltSquareDown, faGlass as faGlassEmpty, faGift, faFlag, faHandWave, faArrowAltSquareUp, faArrowDown, faArrowUp, faBadge, faBell, faCalendarAlt, faCalendarDay, faCalendarWeek, faChartLine, faPencil, faCogs, faGear, faCreditCardFront, faEnvelope, faEquals, faFlagCheckered, faInfoCircle, faLock, faPlus, faQuestionCircle, faSave, faStarShooting, faThumbsUp, faTrashCan, faTrophy, faUsers, faUserVisor, faWeight } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck, faGlass, faBlinds, faGlassHalf } from '@fortawesome/pro-solid-svg-icons';
import { ForgotPassword } from "external/ForgotPassword";
import { ResetPassword } from "external/ResetPassword";
import { Signup } from "external/Signup";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from "./external/Login";
import { Settings } from "./internal/settings/Settings";
import { SettingsProfile } from "./internal/settings/SettingsProfile";
import { SettingsBilling } from './internal/settings/SettingsBilling';
import { SettingsNotifications } from "./internal/settings/SettingsNotifications";
import { SettingsPassword } from './internal/settings/SettingsPassword';
import { WaterComponent } from './internal/water/Waters';
import { WeightComponent } from "./internal/weight/Weights";
import ReactTooltip from 'react-tooltip';
import { HomepageSwitcher } from "homepage/HomepageSwitcher";
import { Dashboard } from "internal/dashboard/Dashboard";
import { Logout } from "external/Logout";
import { InternalLayout } from "internal/InternalLayout";

library.add(faUsers, faGift, faHandWave, faGlassEmpty, faGlassHalf, faPencil, faTrashCan, faBlinds, faGear, faFlag, faBadge, faBadgeCheck, faInfoCircle, faArrowUp, faArrowDown, faTrophy, faUserVisor, faBell, faCogs, faArrowAltSquareUp, faArrowAltSquareDown, faEquals, faCalendarAlt, faWeight, faFlagCheckered, faStarShooting, faCalendarWeek, faCalendarDay, faCalendarAlt, faGlass, faChartLine, faSave, faPlus, faCreditCardFront, faEnvelope, faLock, faThumbsUp, faQuestionCircle)
am4core.useTheme(am4themes_animated);

export function App() {
	return (
		<div className="App">
			<ToastContainer />
			<ReactTooltip />
			<Routes>
				<Route path="/" element={<HomepageSwitcher />}>
					<Route element={<InternalLayout />}>
						<Route path="/" element={<Dashboard />} />
						<Route path="weight" element={<WeightComponent />} />
						<Route path="water" element={<WaterComponent />} />
						<Route path="settings" element={<Settings />}>
							<Route path="" element={<SettingsProfile />} />
							<Route path="password" element={<SettingsPassword />} />
							<Route path="notifications" element={<SettingsNotifications />} />
							<Route path="billing" element={<SettingsBilling />} />
						</Route>
					</Route>
				</Route>

				<Route path="login" element={<Login />} />
				<Route path="signup" element={<Signup />} />
				<Route path="forgot" element={<ForgotPassword />} />
				<Route path="reset" element={<ResetPassword />} />
				<Route path="logout" element={<Logout />} />
			</Routes>
		</div>
	);
}
