import { isSameDay } from "date-fns";
import { getToday } from "../helpers/date";
import { DateMaybeObject, DateObject } from "./date-object";

export type Water = DateObject;

export const getTodaysWater = (waters: DateMaybeObject[]): number => {

	if (!waters) {
		return 0;
	}

	const today = getToday();

	const water = waters.find(w => isSameDay(w.date, today));

	return water && water.amount ? water.amount : 0;
};

export const hasLoggedWaterToday = (waters: Water[]) => {

	if (!waters) {
		return false;
	}

	const today = getToday();

	return !!waters.find(w => isSameDay(w.date, today));
};
