import Joi, { StringSchema } from 'joi';

export const emailValidator: StringSchema = Joi.string().email({ tlds: { allow: false } }).max(255).required().messages({
	'string.base': 'Please enter a valid email address',
	'string.empty': 'Please enter a valid email address',
	'string.email': 'Please enter a valid email address',
	'string.max': 'Please enter less than {#limit} characters',
	'any.required': 'Please enter a valid email address'
});

export const personNameValidator: StringSchema = Joi.string().min(1).max(200).required().messages({
	'string.base': 'Please enter your name',
	'string.empty': 'Please enter your name',
	'string.min': 'Please enter at least one character',
	'string.max': 'Please enter less than 200 characters',
	'any.required': 'Please enter your name'
});

export const passwordValidator: StringSchema = Joi.string().min(8).max(1000).required().messages({
	'string.base': 'Please enter a password',
	'string.empty': 'Please enter a password',
	'string.min': 'Please enter at least {#limit} characters',
	'string.max': 'Please enter less than {#limit} characters',
	'any.required': 'Please enter a password'
});
