import { Bullet, DateAxis, LineSeries, ValueAxis, XYChart, XYCursor } from '@amcharts/amcharts4/charts';
import { create, Rectangle, Scrollbar } from '@amcharts/amcharts4/core';
import { useEffect, useState } from "react";
import { DateMaybeObject, DateObject, sortAsc } from "../../models/date-object";

export const LineChart = (props: { data: DateObject[] }) => {

	const [chart, setChart] = useState<XYChart | undefined>(undefined)
	const [data, setData] = useState(props.data as DateMaybeObject[]);
	useEffect(() => setData(props.data), [props.data]);

	useEffect(() => addData(chart, data), [data]);

	useEffect(() => {

		const tempChart = create("linechart", XYChart);

		tempChart.dateFormatter.dateFormat = 'MMMM dt, yyyy';

		// Add vertical scrollbar
		tempChart.scrollbarX = new Scrollbar();
		tempChart.scrollbarX.marginBottom = 20;

		tempChart.cursor = new XYCursor();
		tempChart.cursor.lineX.disabled = true;
		tempChart.cursor.lineY.disabled = true;

		// console.log(data);
		// Add data
		addData(tempChart, data || []);

		addDateXAxis(tempChart);
		addWeightSeries(tempChart);

		setChart(tempChart);

		return function cleanup() {
			if (chart) {
				chart.dispose();
			}
		}
	}, []);

	/**
	 * Add the weights to the chart
	 * @param chart The chart
	 * @param weights Weights to add
	 */
	function addData(chart: XYChart | undefined, data: DateMaybeObject[]) {

		if (chart) {
			chart.data = [...data].sort(sortAsc);
		} else {
			console.log('chart is null so not adding data');
		}
	}

	/**
	 * Add the X (Date) axis
	 * @param chart The chart
	 */
	function addDateXAxis(chart: XYChart) {

		const axis = chart.xAxes.push(new DateAxis());

		if (axis.tooltip) {
			axis.tooltip.disabled = true;
		}

		axis.renderer.minLabelPosition = 0.05;
		axis.renderer.maxLabelPosition = 0.95;
		axis.baseInterval.timeUnit = 'day';
		axis.baseInterval.count = 1;

		return axis;
	}

	/**
	 * Add the Y (value) axis
	 * @param chart The chart
	 */
	function addYAxis(chart: XYChart) {

		const axis = chart.yAxes.push(new ValueAxis());

		axis.renderer.grid.template.disabled = true;

		return axis;
	}

	/**
	 * Set up the weight series
	 * @param chart The chart
	 */
	function addWeightSeries(chart: XYChart) {

		const axis = addYAxis(chart);

		const series = chart.series.push(new LineSeries());
		series.dataFields.valueY = 'amount';
		series.dataFields.dateX = 'date';
		series.yAxis = axis;

		axis.formatLabel = (value) => `${value} lbs`;

		series.strokeWidth = 3;
		series.propertyFields.stroke = 'color';

		const bullet = series.bullets.push(new Bullet());
		bullet.width = 10;
		bullet.height = 10;
		bullet.horizontalCenter = 'middle';
		bullet.verticalCenter = 'middle';
		bullet.propertyFields.fill = 'bullet'; // tooltips grab fill from parent by default
		bullet.tooltipText = '[#410524 font-size: 1.75em]{dateX}\n[/][#2b0318 font-size: 2.25em]{valueY} lbs[/]';

		const rectangle = bullet.createChild(Rectangle);
		rectangle.propertyFields.stroke = 'bullet';
		rectangle.strokeWidth = 2;
		rectangle.width = 10;
		rectangle.height = 10;

		const hoverState = bullet.states.create('hover');
		hoverState.properties.scale = 2;
	}

	return (
		<div className="bg-white shadow rounded-lg h-full px-10 pb-10">
			<div id="linechart" className="h-full w-full"></div>
		</div>
	);
}
