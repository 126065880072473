import { ErrorBox } from "components/ErrorBox";
import { ValidationError } from "components/ValidationError";
import { emailValidator, personNameValidator } from "helpers/validators";
import { useEffect, useState } from "react";
import { getMe, updateMe } from "services/Api";
import { critical, success } from "services/Notifications";

export function SettingsProfile() {

	const [name, setName] = useState('');
	const [nameError, setNameError] = useState('');

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		getMe().then(me => {
			setEmail(me.email);
			setName(me.name);
		}).catch(e => critical(e));
	}, []);

	function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {

		// we don't care if it's valid here, just show the message
		validateName(event.target.value)

		setName(event.target.value);
	}

	function onEmailChange(event: React.ChangeEvent<HTMLInputElement>) {

		// we don't care if it's valid here, just show the message
		validateEmail(event.target.value)

		setEmail(event.target.value);
	}

	function updateProfile() {

		if (!validateName(name) || !validateEmail(email)) {
			return;
		}

		updateMe({ email, name })
			.then(() => success('Your profile has been updated!'))
			.catch(e => setError(e.message));
	}

	function validateName(name: string) {

		const { error } = personNameValidator.validate(name);

		if (error) {
			setNameError(error.message);
			return false;
		} else {
			setNameError('');
			return true;
		}
	}

	function validateEmail(email: string) {

		const { error } = emailValidator.validate(email);

		if (error) {
			setEmailError(error.message);
			return false;
		} else {
			setEmailError('');
			return true;
		}
	}

	return (
		<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
			<div className="md:grid md:grid-cols-3 md:gap-6">
				<div className="md:col-span-1">
					<h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
					<p className="mt-1 text-sm text-gray-500">Your name and email address</p>
				</div>
				<div className="mt-5 md:col-span-2 md:mt-0">
					<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">Profile</h1>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<form>
							<div className="px-4 py-5 sm:p-6">
								<ErrorBox error={error}></ErrorBox>
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">First name</label>
										<input value={name} onChange={onNameChange} type="text" name="first-name" autoComplete="given-name" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
										<ValidationError error={nameError}></ValidationError>
									</div>
									<div className="col-span-6">
										<label htmlFor="email-address" className="block text-sm font-medium text-gray-700">Email Address</label>
										<input value={email} onChange={onEmailChange} type="text" name="email-address" autoComplete="email" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
										<ValidationError error={emailError}></ValidationError>
									</div>
									<div className="col-span-6">
										<button type="button" onClick={updateProfile} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											Save
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
