import { isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { BodyChart } from '../../components/charts/BodyChart';
import { ColumnChart } from '../../components/charts/ColumnChart';
import { Water } from '../../models/water';
import { getWaters } from '../../services/Api';
import { critical } from '../../services/Notifications';
import { WatersList } from './WatersList';
import { TodaysWaterWidget } from './TodaysWaterWidget';
import { NoWatersComponent } from './NoWaters';

export const WaterComponent = () => {

	const [waters, setWaters] = useState([] as Water[]);

	useEffect(() => {
		getWaters()
			.then(waters => setWaters(waters))
			.catch(e => critical(e));
	}, []);

	function hasWaters() {
		return waters.length > 0;
	}

	function addWater(water: Water) {
		if (waters.some(w => isSameDay(w.date, water.date))) {
			setWaters(waters.map(w => isSameDay(w.date, water.date) ? water : w));
		} else {
			setWaters([...waters, water]);
		}
	}

	async function onEdit(water: Water) {
		setWaters(waters.map(w => isSameDay(w.date, water.date) ? water : w));
	}

	async function onDelete(water: Water) {
		setWaters(waters.filter(w => w.date !== water.date));
	}

	return (
		<div>
			{!hasWaters() && <NoWatersComponent onEnterWater={addWater}></NoWatersComponent>}
			{hasWaters() && <div className="p-8 flex justify-between w-full">
				<div className="w-2/5 mr-8">
					<TodaysWaterWidget onAdd={addWater} waters={waters}></TodaysWaterWidget>
					<div className="mt-10">
						<WatersList onDelete={onDelete} onEdit={onEdit} waters={waters}></WatersList>
					</div>
				</div>
				<div className="w-3/5">
					<div className="">
						<BodyChart data={waters}></BodyChart>
					</div>
					<div className="mt-10">
						<ColumnChart data={waters} ></ColumnChart>
					</div>
				</div>
			</div>}
		</div>
	)
}
