import { ColumnSeries, DateAxis, ValueAxis, XYChart } from '@amcharts/amcharts4/charts';
import { color, create } from '@amcharts/amcharts4/core';
import { useEffect, useState } from 'react';
import { DateMaybeObject, DateObject, sortAsc } from '../../models/date-object';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const ColumnChart = (props: { data: DateObject[] }) => {

	const [chart, setChart] = useState<XYChart | undefined>(undefined)

	useEffect(() => {

		const tempChart = create('columnchart', XYChart);

		tempChart.dateFormatter.dateFormat = 'MMMM dt, yyyy';

		addXAxis(tempChart);
		addYAxis(tempChart);

		addSeries(tempChart);

		setChart(tempChart);

		return function cleanup() {
			if (chart) {
				chart.dispose();
			}
		}

	}, [])

	useEffect(() => {
		if (chart) {
			addData(chart, props.data);
		}

	}, [props.data, chart]);

	/**
	 * Add a date axis
	 * @param chart The chart
	 */
	function addXAxis(chart: XYChart) {

		const axis = chart.xAxes.push(new DateAxis());

		axis.baseInterval.timeUnit = 'day';
		axis.baseInterval.count = 1;
		axis.renderer.grid.template.location = 0;
		axis.renderer.minGridDistance = 30;
		axis.renderer.labels.template.horizontalCenter = 'right';
		axis.renderer.labels.template.verticalCenter = 'top';
		axis.renderer.labels.template.rotation = 315;
		axis.renderer.minHeight = 110;
		axis.renderer.grid.template.disabled = true;

		if (axis.tooltip) {
			axis.tooltip.disabled = true;
		}

		return axis;
	}

	/**
	 * Add the Y (value) axis
	 * @param chart The chart
	 */
	function addYAxis(chart: XYChart) {

		const axis = chart.yAxes.push(new ValueAxis());

		axis.formatLabel = (value) => `${value} oz`;
		axis.renderer.grid.template.disabled = true;
		axis.min = 0;
		axis.max = 140;
		axis.strictMinMax = true;

		const goalLine = axis.axisRanges.create();
		goalLine.value = 128;
		goalLine.grid.stroke = color('#2bec42');
		goalLine.grid.strokeWidth = 2;
		goalLine.grid.strokeOpacity = 1;
		goalLine.label.fill = goalLine.grid.stroke;
		goalLine.label.inside = true;
		// goalLine.label.text = 'Recommended (128 oz)';
		goalLine.label.verticalCenter = 'bottom';

		return axis;
	}

	function addData(chart: XYChart, data: DateMaybeObject[]) {

		const sorted = [...data].sort(sortAsc);

		chart.data = sorted;
	}

	/**
	 * Set up the data series
	 * @param chart The chart
	 */
	function addSeries(chart: XYChart) {

		const series = chart.series.push(new ColumnSeries());

		series.columns.template.propertyFields.fill = 'color';
		series.columns.template.propertyFields.stroke = 'color';
		series.dataFields.valueY = 'amount';
		series.dataFields.dateX = 'date';
		series.columns.template.tooltipText = '[#fff font-size: 16px]{dateX}\n[/][#fff font-size: 24px]{valueY} oz[/]';
		series.columns.template.column.cornerRadiusTopLeft = 2;
		series.columns.template.column.cornerRadiusTopRight = 2;

		if (series.tooltip) {
			series.tooltip.pointerOrientation = 'vertical';
		}
	}

	return (
		<div className="bg-white shadow rounded-lg p-5">
			<div id="columnchart" style={{ width: "100%", height: "500px" }}></div>
		</div>
	)
}
