import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { addOrUpdateWeight } from "services/Api";
import { promptForDateAndAmount } from "services/Notifications";
import { Weight } from "../../models/weight";

export function CurrentWeightWidget(props: { weights: Weight[], onEnterWeight: (weight: Weight) => void }) {

	// users weights
	const [weights, setWeights] = useState(props.weights);

	// current weight
	const [current, setCurrent] = useState('--');

	useEffect(() => setWeights(props.weights), [props.weights]);

	useEffect(() => {

		const current = weights[0];

		if (current) {
			setCurrent(`${current.amount}lbs`);
		}

	}, [weights]);

	async function enterWeight() {

		const prompts = await promptForDateAndAmount('Enter your weight', true);

		if (!prompts) {
			return;
		}

		const weight = await addOrUpdateWeight(prompts.date, prompts.amount);

		props.onEnterWeight(weight);
	}

	return (

		<div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-dark rounded-lg overflow-hidden">
			<dt>
				<div className="absolute rounded-md p-3">
					<FontAwesomeIcon icon={['far', 'weight']} className="text-2xl" />
				</div>
				<p className="ml-16 text-sm font-medium text-gray-500 truncate">Current Weight</p>
			</dt>
			<dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
				<p className="text-2xl font-semibold text-gray-900">{current}</p>
				<p className="ml-2 flex items-baseline text-sm font-semibold text-green-600">
				</p>
				<div className="absolute bottom-0 inset-x-0 bg-gray-100 px-4 py-4 sm:px-6">
					<div className="text-sm">
						<button onClick={enterWeight} type="button" className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
							<FontAwesomeIcon icon={['far', 'weight']} className="mr-2" />
							Enter Weight
						</button>
					</div>
				</div>
			</dd>
		</div>
	)
}
