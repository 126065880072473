import { IconName } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { addOrUpdateWeight, deleteWeight } from 'services/Api';
import { showDeleteConfirmationPrompt, promptForDateAndAmount } from 'services/Notifications';
import { formatIsoDate, formatLongDate, timeago } from '../../helpers/date';
import { Weight } from '../../models/weight';

export const WeightsListComponent = (props: { weights: Weight[], onEdit: (weight: Weight) => void, onDelete: (weight: Weight) => void }) => {

	const [weights, setWeights] = useState(props.weights);

	useEffect(() => {
		setWeights(props.weights);
		ReactTooltip.rebuild();
	}, [props.weights]);

	async function onEdit(weight: Weight) {

		const prompts = await promptForDateAndAmount('Please enter your weight', false, weight.amount, weight.date);

		if (!prompts) {
			return;
		}

		const newWeight = await addOrUpdateWeight(prompts.date, prompts.amount);

		props.onEdit(newWeight);
	}

	async function onDelete(weight: Weight) {

		const confirm = await showDeleteConfirmationPrompt('Delete Your Weight?', `Are you sure you want to delete ${format(weight.date, 'EEEE')}'s weight??`);

		if (!confirm) {
			return;
		}

		await deleteWeight(weight);

		props.onDelete(weight);
	}

	function getIconAndText(index: number): { icon: IconName, text: string } {

		const weight = weights[index];
		const next = weights[index + 1]

		// assume a sorted list. [0] is most recent
		if (!weight || !next) {
			return {
				icon: 'flag',
				text: 'Start'
			};
		}

		if (weight.amount === next.amount) {
			return {
				icon: 'equals',
				text: 'No change'
			};
		}

		if (weight.amount > next.amount) {
			return {
				icon: 'arrow-up',
				text: `Increase of ${weight.amount - next.amount}lbs`
			};
		}

		if (weight.amount < next.amount) {
			return {
				icon: 'arrow-down',
				text: `Decrease of ${next.amount - weight.amount}lbs`
			};
		}

		return {
			icon: 'flag',
			text: 'Start'
		};
	}

	return (
		<div className="mt-10 min-h-full">
			<div className="shadow border-b border-gray-200 sm:rounded-lg h-auto overflow-y-scroll">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-200">
						<tr>
							<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Date
							</th>
							<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Amount
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{
							weights.map((weight, index) => {

								const iconAndText = getIconAndText(index);

								return (
									<tr key={formatIsoDate(weight.date)}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="flex items-center">
												<div className="flex-shrink-0 h-10 w-10">
													<FontAwesomeIcon data-tip={`${iconAndText.text}`} icon={['far', iconAndText.icon]} className="text-4xl" />
												</div>
												<div className="ml-4">
													<div className="text-sm font-medium text-gray-900">
														{formatLongDate(weight.date)}
													</div>
													<div className="text-sm text-gray-500">
														{timeago(weight.date)}
													</div>
												</div>
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">{weight.amount}lbs</div>
											<div className="text-sm text-gray-500">{iconAndText.text}</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
											<button onClick={() => onEdit(weight)} className="text-indigo-600 hover:text-indigo-900">Edit</button>
											<button onClick={() => onDelete(weight)} className="text-indigo-600 hover:text-indigo-900 ml-5">Delete</button>
										</td>
									</tr>
								)
							})
						}
					</tbody>
				</table>
			</div>
		</div>

	)
}


