import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Water } from '../../models/water';
import { addWater } from '../../services/Api';
import { promptForDateAndAmount } from '../../services/Notifications';

export const NoWatersComponent = (props: { onEnterWater: (water: Water) => void }) => {

	async function promptForWater() {
		const prompts = await promptForDateAndAmount('Enter Amount', true);

		if (!prompts) {
			return;
		}

		const water = await addWater(prompts.date, prompts.amount);

		props.onEnterWater(water)
	}

	return (
		<div className="flex justify-center items-center h-screen-without-header">
			<div className="text-center">
				<FontAwesomeIcon icon={['fas', 'glass']} className="mr-2 text-5xl" />
				<h3 className="mt-2 text-sm font-medium text-gray-900">No waters</h3>
				<p className="mt-1 text-sm text-gray-500">Stay hydrated by tracking your daily water intake</p>
				<div className="mt-6">
					<button onClick={promptForWater} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<FontAwesomeIcon icon={['fas', 'glass']} className="mr-2" />
						Log Your Water
					</button>
				</div>
			</div>
		</div>
	)
}
