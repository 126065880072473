import { color } from "@amcharts/amcharts4/core";

export const gray = color('#9aa5b1');
export const green = color('#65d6ad');
export const red = color('#e56a6a');
export const pink = color('#d9117b');
export const blue = color('#2bafec');

export const pee0 = color('#8e2b0a');
export const pee1 = color('#c96d16');
export const pee2 = color('#f0b428');
export const pee3 = color('#fadb60');
export const pee4 = color('#fef2c1');