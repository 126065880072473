import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Awards() {

	return (
		<div className="border-b h-full border-gray-200 bg-white p-5">
			<div className="flex justify-center items-center h-screen-without-header">
				<div className="text-center">
					<FontAwesomeIcon icon={['far', 'gift']} className="mr-2 text-5xl" />
					<h3 className="mt-2 text-sm font-medium text-gray-900">Coming Soon!</h3>
					<p className="mt-1 text-sm text-gray-500">We're constantly working on new features. Check back soon!</p>
				</div>
			</div>
		</div>
	)
}
