import * as React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class ErrorBoundary extends React.Component {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any) {
		console.log('error boundary');
		console.log(error);

		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	render() {

		if ((this.state as any).hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return (this.props as any).children;
	}
}
