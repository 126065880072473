import "flatpickr/dist/themes/material_blue.css";
import { formatIsoDate } from "helpers/date";
import { useState } from "react";
import Flatpickr from "react-flatpickr";

export function DateAmountPrompt(props: { amount: number | undefined, date: string, showDate: boolean, onChange: (date: string) => void }) {

	const [amount, setAmount] = useState(props.amount || '');
	const [date, setDate] = useState(props.date);

	return (
		<div>
			<label htmlFor="prompt-amount" className="block">Amount</label>
			<input value={amount} onChange={e => setAmount(e.target.value)} className="swal2-input" id="prompt-amount" type="number" />

			{props.showDate && <div>
				<label htmlFor="prompt-amount" className="block mt-3">Date</label>
				<Flatpickr
					id="prompt-date"
					data-enable-time
					className="swal2-input"
					value={date}
					onChange={date => {
						const formattedDate = formatIsoDate(date[0]);
						setDate(formattedDate)
						props.onChange(formattedDate)
					}}
					options={{
						altInput: true,
						altFormat: "F j, Y",
						dateFormat: "Y-m-d",
						enableTime: false
					}}
				/>
			</div>
			}
		</div>
	)
}
