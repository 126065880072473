import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"

export function ErrorBox(props: { error: string | null }) {

	const [error, setError] = useState(props.error);
	useEffect(() => setError(props.error), [props]);

	if (!error) {
		return (
			<div>

			</div>
		)
	}

	return (
		<div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 mb-5" role="alert">
			<FontAwesomeIcon icon={['far', 'info-circle']} className="text-3xl mr-3" />
			<p>{error}</p>
		</div>
	)
}