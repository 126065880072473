import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { SESSION_ID } from "services/Api";
import { Homepage } from "./Homepage";

export function HomepageSwitcher() {

	const [sessionId, setSessionId] = useState(SESSION_ID);
	useEffect(() => setSessionId(SESSION_ID), [SESSION_ID]);

	if (sessionId) {
		return <Outlet />
	} else {
		return <Homepage />
	}
}
