import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBox } from "components/ErrorBox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "services/Api";
import { prompt } from "services/Notifications";

export function SettingsCloseAccount() {

	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	async function onCloseClicked() {

		const confirmed = await prompt({
			title: 'Close Your Account?',
			text: 'We respect your privacy. When you close your account, we will delete all your data. It cannot be restored. Are you sure?',
			icon: 'question',
			confirmButtonText: 'Close Account',
			showCancelButton: true,
			confirmButtonColor: '#e53e3e'
			// tslint:disable:no-console
		});

		if (confirmed) {
			deleteAccount()
				.then(() => navigate(0))
				.catch(error => setError(error));
		}
	}

	return (
		<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
			<div className="md:grid md:grid-cols-3 md:gap-6">
				<div className="md:col-span-1">
					<h3 className="text-lg font-medium leading-6 text-gray-900">Close Account</h3>
					<p className="mt-1 text-sm text-gray-500">We hate services that make it hard to cancel. We don't want you to leave, but if you do, we make it easy</p>
				</div>
				<div className="mt-5 md:col-span-2 md:mt-0">
					<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">Confirm</h1>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<form>
							<div className="px-4 py-5 sm:p-6">
								<ErrorBox error={error}></ErrorBox>

								<button onClick={onCloseClicked} type="button" className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
									<FontAwesomeIcon icon={['far', 'hand-wave']} className="mr-2" />
									Close Account
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
