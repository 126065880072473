import { isSameDay } from "date-fns";
import { TodaysWaterWidget } from "internal/water/TodaysWaterWidget"
import { CurrentWeightWidget } from "internal/weight/CurrentWeightWidget"
import { sortDesc } from "models/date-object";
import { Water } from "models/water";
import { Weight } from "models/weight";
import { useState, useEffect } from "react";
import { getWaters, getWeights } from "services/Api";
import { critical } from "services/Notifications";
import { ActivityGraph } from "./ActivityGraph";
import { Awards } from "internal/dashboard/Awards";

export function Dashboard() {

	const [weights, setWeights] = useState([] as Weight[]);
	const [waters, setWaters] = useState([] as Water[]);

	async function addWeight(weight: Weight) {
		setWeights([...weights, weight].sort(sortDesc));
	}

	function addWater(water: Water) {
		if (waters.some(w => isSameDay(w.date, water.date))) {
			setWaters(waters.map(w => isSameDay(w.date, water.date) ? water : w));
		} else {
			setWaters([...waters, water]);
		}
	}

	useEffect(() => {
		getWeights()
			.then(weights => setWeights(weights.sort(sortDesc)))
			.catch((e) => critical(e));

		getWaters()
			.then(waters => setWaters(waters))
			.catch(e => critical(e));
	}, []);

	return (
		<div className="p-8">
			<div className="flex justify-between w-full">
				<div className="w-1/2 mr-4 md:w-full">
					<CurrentWeightWidget weights={weights} onEnterWeight={addWeight}></CurrentWeightWidget>
				</div>
				<div className="w-1/2 ml-4 md:w-full">
					<TodaysWaterWidget waters={waters} onAdd={addWater}></TodaysWaterWidget>
				</div>
			</div>
			<div className="flex justify-between mt-8 w-full">
				<div className="w-1/2 mr-4 md:w-full">
					<ActivityGraph weights={weights} waters={waters}></ActivityGraph>
				</div>
				<div className="w-1/2 ml-4 md:w-full">
					<Awards></Awards>
				</div>
			</div>
		</div>
	)
}
