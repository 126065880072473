import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { logout } from "services/Api"

export function Logout() {

	const navigate = useNavigate();

	useEffect(() => {
		logout().then(() => {
			navigate('/')
		});
	}, [])

	return (
		<div></div>
	)
}
