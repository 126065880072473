import { format, parseISO } from "date-fns";
import { DateStringObject } from "models/date-object";
import { Session } from "models/session";
import { User } from "models/user";
import { Water } from '../models/water';
import { Weight } from "../models/weight";

export const BASE_URL = process.env.REACT_APP_API_URL;

export let SESSION_ID: string | null = null;

export async function login(email: string, password: string, turnstileToken: string) {

	const response = await post<Session>(`/login`, { email, password, turnstileToken });

	storeSessionId(response.sessionId);
}

export async function register(email: string, password: string, firstName: string, lastName: string, turnstileToken: string) {

	const response = await post<Session>(`/register`, { email, password, firstName, lastName, turnstileToken });

	storeSessionId(response.sessionId);
}

export async function forgotPassword(email: string) {

	const success = await post(`/forgot`, { email })

	return success;
}

export async function validateToken(token: string) {

	const valid = await post(`/reset/${token}`, {});

	return valid;
}

export async function resetPassword(token: string, password: string) {

	const response = await post(`/reset/${token}`, { password });

	return response;
}

export function logout() {

	// regardless if we clear it on the backend, clear the session locally
	return del('/logout')
		.then(() => clearSessionId())
		.catch(() => clearSessionId());

}

export async function getMe() {

	const me = await get<User>(`/me`);

	return me;
}

export async function updateMe(me: { email: string; name: string }) {
	return await post(`/me`, me)
}

export async function changePassword(data: { oldPassword: string; newPassword: string }) {
	return await post('/me/password', data);
}

export async function deleteAccount() {

	await del(`/me`);

	clearSessionId();
}

export async function getWeights(): Promise<Weight[]> {

	const weights = await get<DateStringObject[]>(`/weights`);

	return weights.map(weight => {
		console.log(weight);
		return { ...weight, date: parseISO(weight.date) }
	});
}

export async function addOrUpdateWeight(date: Date | string, amount: number) {

	const formattedDate = date instanceof Date ? format(date, 'yyyy-MM-dd') : date;

	const weight = await post<DateStringObject>(`/weights/${formattedDate}`, { amount });

	return { ...weight, date: parseISO(weight.date) };
}

export async function deleteWeight(weight: Weight) {
	return del(`/weights/${format(weight.date, 'yyyy-M-d')}`)
}

export async function getWaters(): Promise<Water[]> {

	const waters = await get<DateStringObject[]>(`/waters`);

	return waters.map(water => {
		return { ...water, date: parseISO(water.date) }
	});
}

export async function addWater(date: Date | string, amount: number) {

	const formattedDate = date instanceof Date ? format(date, 'yyyy-MM-dd') : date;

	const water = await post<DateStringObject>(`/waters/${formattedDate}`, { amount });

	return { ...water, date: parseISO(water.date) };
}

export async function updateWater(date: Date | string, amount: number): Promise<Water> {

	const formattedDate = date instanceof Date ? format(date, 'yyyy-MM-dd') : date;

	const water = await put<DateStringObject>(`/waters/${formattedDate}`, { amount });

	return { ...water, date: parseISO(water.date) };
}

export async function deleteWater(water: Water) {
	return del(`/waters/${format(water.date, 'yyyy-M-d')}`)
}

// export async function getWaterStats() {
// 	return getWaters().pipe(map(waters => {

// 		const amounts: Water[] = [];

// 		// tslint:disable-next-line: no-let
// 		for (let i = 0; i < 14; i++) {

// 			const date = subDays(today, i);

// 			const water = waters.find(w => isSameDay(date, w.date));

// 			amounts.push({
// 				date,
// 				amount: water ? water.amount : 0,
// 				color: water && water.amount >= 128 ? '#65d6ad' : '#e56a6a'
// 			});
// 		}

// 		const average = amounts.map(w => w.amount).reduce((a, b) => (a + b)) / amounts.length;

// 		const todaysWater = waters.find(w => isToday(w.date));

// 		return {
// 			today: todaysWater ? todaysWater.amount : 0,
// 			average: Math.round(average),
// 			amounts
// 		};
// 	}));
// }


// export async function login(request: { email: string; password: string }) {
// 	return post(`/login`, request)
// 		.pipe(map(response => HttpService.initializeUser(response)));
// }

// export async function register(request: { email: string; password: string; firstName: string; lastName: string }) {
// 	return post(`/register`, request)
// 		.pipe(map(response => HttpService.initializeUser(response)));
// }

// export async function forgotPassword(email: string) {
// 	return post(`/forgot`, { email })
// 		.pipe(map(response => response.success));
// }

// export async function validateToken(token: string) {
// 	return post(`/reset/${token}`)
// 		.pipe(map(response => response.valid));
// }

// export async function resetPassword(token: string, password: string) {
// 	return post(`/reset/${token}`, { password })
// 		.pipe(map(response => HttpService.initializeUser(response)));
// }

// export async function logout() {
// 	HttpService.logout();
// }

// export async function loadMe() {
// 	return get(`/me`)
// 		.pipe(map(me => dispatch(new LoadMe(me))));
// }

// export async function getMe() {
// 	return store.select(state => state.me);
// }

// export async function updateMe(me: { email: string; firstName: string; lastName: string }) {
// 	return post(`/me`, me)
// 		.pipe(map(updated => dispatch(new LoadMe(updated))));
// }

// export async function changePassword(data: { old: string; new: string }) {
// 	return post('/me/password', data);
// }

// export async function searchFoods(term: string) {

// 	if (!term) {

// 		dispatch(new SetFoodSearchTerm(term));
// 		dispatch(new LoadFoodSearchResults([]));

// 		return empty();
// 	}

// 	return foodIndex
// 		.search(term)
// 		.then(({ hits }) => {

// 			const foods = hits.map((hit: any) => {
// 				return { id: hit.objectID, company: hit.company, name: hit.name, servingSize: hit.servingSize, nutrition: { calories: hit.calories, protein: hit.protein, fat: hit.fat, carbs: hit.carbs } };
// 			});

// 			dispatch(new SetFoodSearchTerm(term));
// 			dispatch(new LoadFoodSearchResults(foods as any[]));
// 		})
// 		.catch(err => {
// 			console.log(err);
// 		});
// }

// /**
//  * Get the list of foods to display
//  * The list might show the following:
//  * 	2) The list of custom foods and recent foods
//  * 	3) The search results
//  */
// export async function getDisplayFoods() {
// 	return store.select(createSelector(
// 		state => state.nutrition.foods,
// 		state => state.nutrition.search,
// 		state => state.nutrition.popular,
// 		// tslint:disable-next-line: max-func-args
// 		(foods, search, popular) => {

// 			// if there's a search term, show the backend results
// 			// otherwise, get recent foods
// 			if (search.term) {
// 				return {
// 					text: `Showing results for: ${search.term}`,
// 					foods: search.results
// 				};
// 			}

// 			if (!foods.size) {
// 				return { text: `Here are some popular foods to get started`, foods: popular };
// 			}

// 			return { text: 'Your Foods', foods: Array.from(foods.values()) };
// 		}));
// }

// /**
//  * Load a food from the database
//  * @param id The id of the food
//  */
// export async function loadFood(id: number) {
// 	return get(`/foods/${id}`)
// 		.pipe(map(food => dispatch(new AddFood(food))));
// }

// /**
//  * Get an observable of a food
//  * @param id The id of the food
//  */
// export async function getFood(id: number) {
// 	return store.select(state => state.nutrition.foods)
// 		.pipe(map(foods => foods.get(id)));
// }

// /**
//  * Load the user's custom foods
//  */
// export async function loadCustomFoods() {
// 	return get(`/foods/custom`)
// 		.pipe(map(foods => dispatch(new LoadFoods(foods))));
// }

// /**
//  * Load popular foods for showing when there are no other foods
//  */
// export async function loadPopularFoods() {
// 	return get(`/foods/popular`)
// 		.pipe(map(foods => dispatch(new LoadPopularFoods(foods))));
// }

// /**
//  * Get an observable of all the users custom foods
//  */
// export async function getCustomFoods() {
// 	return store.select(state => state.nutrition.foods)
// 		.pipe(map(foods => Array.from(foods.values()).filter(f => f.isCustom)));
// }

// /**
//  * Add a custom food to the user's account
//  * @param name The name of the custom food
//  * @param nutrition The food's nutrition information
//  */
// export async function addCustomFood(name: string, nutrition: NutritionInformation) {
// 	return post(`/foods/custom`, { name, nutrition })
// 		.pipe(map(food => dispatch(new AddFood(food))));
// }

// /**
//  * Update a user's custom food and update the nutrition log
//  * @param id The food id
//  * @param name The custom food name
//  * @param nutrition The custom food's nutrition information
//  */
// export async function updateCustomFood(id: number, name: string, nutrition: NutritionInformation) {
// 	return put(`/foods/custom/${id}`, { name, nutrition })
// 		.pipe(map(food => dispatch(new UpdateFood(food))));
// }

// /**
//  * Delete a custom food and update the nutrition log
//  * @param food The food to delete
//  */
// export async function deleteCustomFood(food: Food) {
// 	return del(`/foods/custom/${food.id}`)
// 		.pipe(map(() => dispatch(new DeleteFood(food))));
// }

// /**
//  * Load the users's nutrition log
//  */
// export async function loadNutritionLog() {
// 	return get(`/nutrition`)
// 		.pipe(map(log => {

// 			dispatch(new LoadFoods(log.foods));

// 			return dispatch(new LoadNutritionEntries(log.entries));
// 		}));
// }

// /**
//  * Get the raw nutrition log, without calculating any totals
//  */
// export async function getRawNutritionLog() {
// 	return store.select(state => state.nutrition.entries);
// }

// /**
//  * Get the user's nutrition log for the currently selected date
//  */
// export async function getNutritionLogForSelectedDate() {
// 	return store.select(state => state.nutrition.selectedDate)
// 		.pipe(switchMap(date => getNutritionLogForDate(date)));
// }

// /**
//  * Get the user's nutrition log for the date
//  */
// export async function getNutritionLogForDate(date: Date) {
// 	return store.select(createSelector(
// 		state => state.nutrition.entries,
// 		state => state.nutrition.foods,
// 		(entries, foods) => {

// 			const entriesForDate = entries.get(toShortString(date)) ?? [];

// 			return entriesForDate.map(entry => {

// 				const food = findFoodById(foods, entry.foodId);

// 				return {
// 					...entry,
// 					food,
// 					total: calculateNutritionInformation(entry.servings, food)
// 				};
// 			});
// 		}));
// }

// /**
//  * Get the total nutrition information for the date
//  */
// export async function getNutritionInformationForDate(date: Date): Observable < NutritionInformation > {
// 	return getNutritionLogForDate(date)
// 		.pipe(map(calculateNutritionInformationForEntries));
// }

// /**
//  * Get the total nutrition information for the store selected date
//  */
// export async function getNutritionInformationForSelectedDate(): Observable < NutritionInformation > {
// 	return getNutritionLogForSelectedDate().pipe(map(calculateNutritionInformationForEntries));
// }

// export async function addFoodToLog(food: Food, details: { servings: number; meal: string; date: string }) {
// 	return post(`/nutrition`, { foodId: food.id, ...details })
// 		.pipe(map(entry => {

// 			dispatch(new AddNutritionEntry(entry));

// 			return dispatch(new AddFood(entry.food));
// 		}));
// }

// export async function updateFoodInLog(existing: NutritionEntry, servings: number) {
// 	return put(`/nutrition/${existing.id}`, { servings })
// 		.pipe(map(entry => {

// 			dispatch(new UpdateNutritionEntry(entry));

// 			return dispatch(new UpdateFood(entry.food));
// 		}));
// }

// export async function removeFoodFromLog(entry: NutritionEntry) {
// 	return del(`/nutrition/${entry.id}`)
// 		.pipe(map(() => dispatch(new DeleteNutritionEntry(entry))));
// }

// export async function getSelectedNutritionDate() {
// 	return store.select(state => state.nutrition.selectedDate);
// }

// export async function updateSelectedNutritionDate(date: Date) {
// 	return dispatch(new SetNutritionDate(date));
// }

// export async function loadWaters() {
// 	return get(`/waters`)
// 		.pipe(map(waters => dispatch(new LoadWaters(waters))));
// }

// export async function loadWeights() {
// 	return get(`/weights`)
// 		.pipe(map(weights => dispatch(new LoadWeights(weights))));
// }

// export async function getWeights() {
// 	return store.select(state => state.weights);
// }

// export async function getWeightStats(): Observable < WeightStats > {

// 	return store.select(store => store.weights).pipe(map((weights) => {
// 		return {
// 			current: getMostRecentWeight(weights),
// 			change: getWeightChange(weights)
// 		};
// 	}));
// }

// export async function addOrUpdateWeight(date: Date | string, amount: number) {

// 	const formattedDate = date instanceof Date ? format(date, 'yyyy-M-d') : date;

// 	return post(`/weights/${formattedDate}`, { amount })
// 		.pipe(map(weight => dispatch(new AddOrUpdateWeight(weight))));
// }

// export async function deleteWeight(weight: Weight) {
// 	return del(`/weights/${format(weight.date, 'yyyy-M-d')}`)
// 		.pipe(map(() => dispatch(new DeleteWeight(weight))));
// }



async function get<TResponse>(path: string) {
	return request<TResponse>('GET', path)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function post<TResponse>(path: string, body: any) {
	return request<TResponse>('POST', path, body)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function put<TResponse>(path: string, body: any) {
	return request<TResponse>('PUT', path, body)
}

async function del(path: string) {
	return request('DELETE', path)
}

async function parseResponse<TBody>(response: Response) {

	let json;

	try {
		json = <TBody>await response.json();
	} catch (ex) {
		json = undefined;
	}

	return {
		statusCode: response.status,
		ok: response.ok,
		json
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function request<TResponse>(method: string, path: string, body?: any): Promise<TResponse> {

	if ((method === 'GET' || method === 'DELETE') && body !== undefined) {
		throw new Error('Cannot have a body with GET or DELETE');
	}

	if ((method === 'POST' || method === 'PUT') && body === undefined) {
		throw new Error('POST or PUT body must not be null');
	}

	const options = body ? {
		method,
		headers: getHeaders(),
		body: JSON.stringify(body)
	} : {
		method,
		headers: getHeaders(),
	}

	return fetch(`${BASE_URL}${path}`, options)
		.then(response => parseResponse(response))
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.then((response: { statusCode: number, ok: boolean, json: any }) => {

			if (response.ok) {
				return response.json as TResponse;
			}
			// extract the error from the server's json
			throw new Error(response.json.message);
		});
}

// function handleResponse(response: any) {

// 	if (response.ok) {
// 		console.log('response is ok')
// 		return response.json()
// 	}

// 	if (response.status === StatusCodes.Unauthorized) {
// 		console.log('response is unauthorized')
// 		return navigate('/login');
// 	}

// 	console.log('throwing error')
// 	const error = new HttpError('There was an error');

// 	error.response = response
// 	error.status = response.status

// 	throw error
// }

/**
 * Check whether there is a session id stored
 *
 * @returns true/false whether there is a session id set
 */
export function isLoggedIn() {
	return SESSION_ID !== null;
}

/**
 * Store a session in localStorage
 *
 * @param sessionId The session id to store in localStorage
 */
function storeSessionId(sessionId: string) {

	if (!sessionId) {
		throw new Error('Missing Session ID');
	}

	SESSION_ID = sessionId;
	localStorage.setItem('session_id', sessionId);
}

/**
 * Get a session id from localStorage, if it exists
 *
 * @returns A session id or undefined
 */
export function loadSessionId() {

	console.log('loading session id');

	const sessionId = localStorage.getItem('session_id');

	SESSION_ID = sessionId;

	console.log(SESSION_ID);
}

/**
 * Clear the session id from everywhere
 */
function clearSessionId() {

	SESSION_ID = null;

	localStorage.removeItem('session_id');
}

/**
 * Get headers to attach to the request, such as Authorization
 *
 * @returns An object containing headers needed for the request
 */
function getHeaders(): Record<string, string> {

	if (SESSION_ID) {
		console.log('yes session id');
		console.log(SESSION_ID);
		return {
			'Content-Type': 'application/json',
			'Authorization': SESSION_ID
		};
	}

	console.log('no session id');

	return {
		'Content-Type': 'application/json'
	};
}
