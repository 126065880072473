import { PictorialStackedSeries, SlicedChart } from '@amcharts/amcharts4/charts';
import { ColorSet, create } from '@amcharts/amcharts4/core';
import { useEffect, useState } from "react";
import { gray, green, pee0, pee1, pee2, pee3, pee4 } from '../../helpers/colors';
import { DateObject } from "../../models/date-object";
import { getTodaysWater } from '../../models/water';

export const BodyChart = (props: { data: DateObject[] }) => {

	const [chart, setChart] = useState<SlicedChart | undefined>(undefined)
	const [series, setSeries] = useState<PictorialStackedSeries | undefined>(undefined)

	useEffect(() => {
		console.log('creating chart')
		const tempChart = create('bodychart', SlicedChart);
		addSeries(tempChart, 0);

		setChart(tempChart);

		return function cleanup() {
			if (chart) {
				chart.dispose();
			}
		}
	}, []);

	useEffect(() => {

		if (chart && series) {

			const todaysWater = getTodaysWater(props.data);
			series.colors = getColors(todaysWater);
			setChartData(chart, todaysWater);
		}

	}, [props.data, chart]);

	/**
		 * Add a series to the chart
		 * @param chart The chart to add the series to
		 * @param waterAmount The total amount of water for today
		 */
	function addSeries(chart: SlicedChart, waterAmount: number) {

		const series = chart.series.push(new PictorialStackedSeries());

		series.colors = getColors(waterAmount);
		series.dataFields.value = 'value';
		series.dataFields.category = 'name';
		series.alignLabels = true;
		series.maskSprite.path = 'M53.5,476c0,14,6.833,21,20.5,21s20.5-7,20.5-21V287h21v189c0,14,6.834,21,20.5,21 c13.667,0,20.5-7,20.5-21V154h10v116c0,7.334,2.5,12.667,7.5,16s10.167,3.333,15.5,0s8-8.667,8-16V145c0-13.334-4.5-23.667-13.5-31 s-21.5-11-37.5-11h-82c-15.333,0-27.833,3.333-37.5,10s-14.5,17-14.5,31v133c0,6,2.667,10.333,8,13s10.5,2.667,15.5,0s7.5-7,7.5-13 V154h10V476 M61.5,42.5c0,11.667,4.167,21.667,12.5,30S92.333,85,104,85s21.667-4.167,30-12.5S146.5,54,146.5,42 c0-11.335-4.167-21.168-12.5-29.5C125.667,4.167,115.667,0,104,0S82.333,4.167,74,12.5S61.5,30.833,61.5,42.5z';
		series.ticks.template.locationX = 1;
		series.ticks.template.locationY = 0.25;
		series.labels.template.text = '{name}: {value.value} oz';
		series.slices.template.tooltipText = '{name}: {value.value} oz';
		series.labelsContainer.width = 200;

		setSeries(series);
	}

	/**
	 * Get the chart data
	 * @param waterAmount Today's water amount
	 */
	function setChartData(chart: SlicedChart, waterAmount: number) {

		if (waterAmount === 0) {
			chart.data = [
				{ name: 'Still to Go', value: 128 }
			];
		}

		else if (waterAmount >= 128) {
			chart.data = [
				{ name: 'Amount Today', value: waterAmount }
			];
		}

		else {
			chart.data = [
				{ name: 'Still to Go', value: 128 - waterAmount },
				{ name: 'Amount Today', value: waterAmount }
			];
		}
	}

	/**
	 * Get the chart colors based on the water amount
	 * @param waterAmount The amount of today's water
	 */
	function getColors(waterAmount: number) {

		const colorSet = new ColorSet();

		if (waterAmount === 0) {
			colorSet.list = [gray];
		}

		else if (waterAmount <= 16) {
			colorSet.list = [gray, pee0];
		}

		else if (waterAmount <= 32) {
			colorSet.list = [gray, pee1];
		}

		else if (waterAmount <= 64) {
			colorSet.list = [gray, pee2];
		}

		else if (waterAmount <= 96) {
			colorSet.list = [gray, pee3];
		}

		else if (waterAmount < 128) {
			colorSet.list = [gray, pee4];
		}

		else if (waterAmount >= 128) {
			colorSet.list = [green];
		}

		return colorSet;
	}

	return (
		<div className="bg-white shadow rounded-lg p-5">
			<div id="bodychart" style={{ width: "100%", height: "500px" }}></div>
		</div>
	);
}
