import { ErrorBox } from 'components/ErrorBox';
import { ValidationError } from 'components/ValidationError';
import { passwordValidator } from 'helpers/validators';
import { useState } from "react";
import { changePassword } from "services/Api";
import { success } from "services/Notifications";

export function SettingsPassword() {

	const [oldPassword, setOldPassword] = useState('');
	const [oldPasswordError, setOldPasswordError] = useState('');

	const [newPassword, setNewPassword] = useState('');
	const [newPasswordError, setNewPasswordError] = useState('');

	const [confirmPassword, setConfirmPassword] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');

	const [error, setError] = useState<string | null>(null);

	function save() {

		if (!validateOldPassword(oldPassword) || !validateNewPassword(newPassword) || !validateConfirmPassword(confirmPassword)) {
			return;
		}

		changePassword({ oldPassword, newPassword })
			.then(() => {
				success('Your password has been changed');
				setError(null);
			})
			.catch(e => setError(e.message))
	}

	function onOldPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {

		validateOldPassword(event.target.value);

		setOldPassword(event.target.value);
	}

	function onNewPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {

		validateNewPassword(event.target.value);

		setNewPassword(event.target.value);
	}

	function onConfirmPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {

		validateConfirmPassword(event.target.value);

		setConfirmPassword(event.target.value);
	}

	function validateOldPassword(password: string) {

		if (!password) {
			setOldPasswordError('Please enter your old password');
			return false;
		} else {
			setOldPasswordError('');
			return true;
		}
	}

	function validateNewPassword(password: string) {

		const { error } = passwordValidator.validate(password);

		if (error) {
			setNewPasswordError(error.message);
			return false;
		} else {
			setNewPasswordError('');
			return true;
		}
	}

	function validateConfirmPassword(password: string) {

		if (password !== newPassword) {
			setConfirmPasswordError('Passwords do not match');
			return false;
		} else {
			setConfirmPasswordError('');
			return true;
		}
	}

	return (
		<div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
			<div className="md:grid md:grid-cols-3 md:gap-6">
				<div className="md:col-span-1">
					<h3 className="text-lg font-medium leading-6 text-gray-900">Password</h3>
					<p className="mt-1 text-sm text-gray-500">Use a password manager with long, unique passwords for the strongest security</p>
				</div>
				<div className="mt-5 md:col-span-2 md:mt-0">
					<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">Password</h1>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<form>
							<div className="px-4 py-5 bg-white sm:p-6">
								<ErrorBox error={error}></ErrorBox>
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6">
										<label htmlFor="old-password" className="block text-sm font-medium text-gray-700">Old Password</label>
										<input value={oldPassword} onChange={onOldPasswordChange} autoComplete="current-password" type="password" name="old-password" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
										<ValidationError error={oldPasswordError}></ValidationError>
									</div>
									<div className="col-span-6">
										<label htmlFor="new-password" className="block text-sm font-medium text-gray-700">New Password</label>
										<input value={newPassword} onChange={onNewPasswordChange} autoComplete="new-password" type="password" name="new-password" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
										<ValidationError error={newPasswordError}></ValidationError>
									</div>
									<div className="col-span-6">
										<label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">Confirm Password</label>
										<input value={confirmPassword} onChange={onConfirmPasswordChange} autoComplete="new-password" type="password" name="confirm-password" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
										<ValidationError error={confirmPasswordError}></ValidationError>
									</div>
									<div className="col-span-6">
										<button type="button" onClick={save} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
											Save
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}
