
export function SettingsBilling() {

	return (
		<div className="p-10">
			<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">Mascot Pro</h1>
			<section aria-labelledby="plan-heading" className="mb-5">
				<form action="#" method="POST">
					<div className="shadow sm:rounded-md sm:overflow-hidden">
						<div className="bg-white py-6 px-4 space-y-6 sm:p-6">
							<div>
								<h2 id="plan-heading" className="text-lg leading-6 font-medium text-gray-900">Plan</h2>
							</div>
							<fieldset>
								<legend className="sr-only">
									Plans
								</legend>
								<div className="relative bg-white rounded-md -space-y-px">
									{/* Checked: "bg-orange-50 border-orange-200 z-10", Not Checked: "border-gray-200" */}
									<label className="rounded-tl-md rounded-tr-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
										<div className="flex items-center text-sm">
											<input type="radio" name="pricing-plan" defaultValue="Startup" className="h-4 w-4 text-orange-500 border-gray-300 focus:ring-gray-900" aria-labelledby="pricing-plans-0-label" aria-describedby="pricing-plans-0-description-0 pricing-plans-0-description-1" />
											<span id="pricing-plans-0-label" className="ml-3 font-medium text-gray-900">Free</span>
										</div>
										<p id="pricing-plans-0-description-0" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
											<span className="font-medium">$0 / mo</span>
										</p>
										{/* Checked: "text-orange-700", Not Checked: "text-gray-500" */}
										<p id="pricing-plans-0-description-1" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">Track your nutrition and weight</p>
									</label>
									{/* Checked: "bg-orange-50 border-orange-200 z-10", Not Checked: "border-gray-200" */}
									<label className="relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
										<div className="flex items-center text-sm">
											<input type="radio" name="pricing-plan" defaultValue="Business" className="h-4 w-4 text-orange-500 border-gray-300 focus:ring-gray-900" aria-labelledby="pricing-plans-1-label" aria-describedby="pricing-plans-1-description-0 pricing-plans-1-description-1" />
											<span id="pricing-plans-1-label" className="ml-3 font-medium text-gray-900">Basic</span>
										</div>
										<p id="pricing-plans-1-description-0" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
											<span className="font-medium">$5 / mo</span>
										</p>
										{/* Checked: "text-orange-700", Not Checked: "text-gray-500" */}
										<p id="pricing-plans-1-description-1" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">All of free, plus track your water and set goals</p>
									</label>
									{/* Checked: "bg-orange-50 border-orange-200 z-10", Not Checked: "border-gray-200" */}
									<label className="rounded-bl-md rounded-br-md relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none">
										<div className="flex items-center text-sm">
											<input type="radio" name="pricing-plan" defaultValue="Enterprise" className="h-4 w-4 text-orange-500 border-gray-300 focus:ring-gray-900" aria-labelledby="pricing-plans-2-label" aria-describedby="pricing-plans-2-description-0 pricing-plans-2-description-1" />
											<span id="pricing-plans-2-label" className="ml-3 font-medium text-gray-900">Professional</span>
										</div>
										<p id="pricing-plans-2-description-0" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
											<span className="font-medium">$9 / mo</span>
										</p>
										{/* Checked: "text-orange-700", Not Checked: "text-gray-500" */}
										<p id="pricing-plans-2-description-1" className="ml-3 pl-1 text-sm md:ml-0 md:pl-0 md:text-right">All of basic, plus custom analytics, as well as access to our extensive knowledge collection. Learn weight loss strategies, find information on nutrients, and more!</p>
									</label>
								</div>
							</fieldset>
							<div className="flex items-center">
								{/* Enabled: "bg-orange-500", Not Enabled: "bg-gray-200" */}
								<button type="button" className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors ease-in-out duration-200" role="switch" aria-checked="true" aria-labelledby="annual-billing-label">
									{/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
									<span aria-hidden="true" className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200" />
								</button>
								<span className="ml-3" id="annual-billing-label">
									<span className="text-sm font-medium text-gray-900">Annual billing </span>
									<span className="text-sm text-gray-500">(Save 10%)</span>
								</span>
							</div>
						</div>
						<div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<button type="submit" className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
								Save
							</button>
						</div>
					</div>
				</form>
			</section>
			<section aria-labelledby="billing-history-heading">
				<div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
					<div className="px-4 sm:px-6">
						<h2 id="billing-history-heading" className="text-lg leading-6 font-medium text-gray-900">Billing history</h2>
					</div>
					<div className="mt-6 flex flex-col">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div className="overflow-hidden border-t border-gray-200">
									<table className="min-w-full divide-y divide-gray-200">
										<thead className="bg-gray-50">
											<tr>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Date
												</th>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Description
												</th>
												<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													Amount
												</th>
												{/*
												`relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                    */}
												<th scope="col" className="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="sr-only">View receipt</span>
												</th>
											</tr>
										</thead>
										<tbody className="bg-white divide-y divide-gray-200">
											<tr>
												<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
													<time dateTime="2020-01-01">1/1/2020</time>
												</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
													Business Plan - Annual Billing
												</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
													CA$109.00
												</td>
												<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
													<a href="#" className="text-orange-600 hover:text-orange-900">View receipt</a>
												</td>
											</tr>
											{/* More payments... */}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>

	)
}