
export const SettingsNotifications = () => {
	return (
		<div className="p-10">
			<h1 className="text-2xl font-bold leading-tight text-gray-900 mb-5">Notifications</h1>
			<div className="pt-6 divide-y divide-gray-200 shadow bg-white">
				<div className="px-4 sm:px-6">
					<div>
						<h2 className="text-lg leading-6 font-medium text-gray-900">Communications</h2>
						<p className="mt-1 text-sm text-gray-500">
							Complete control over how much we contact you
						</p>
					</div>
					<ul className="mt-2 divide-y divide-gray-200">
						<li className="py-4 flex items-center justify-between">
							<div className="flex flex-col">
								<p className="text-sm font-medium text-gray-900" id="privacy-option-1-label">
									Features and Announcements
								</p>
								<p className="text-sm text-gray-500" id="privacy-option-1-description">
									Receive emails about new features and announcements
								</p>
							</div>
							{/* Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" */}
							<button type="button" className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500" role="switch" aria-checked="true" aria-labelledby="privacy-option-1-label" aria-describedby="privacy-option-1-description">
								{/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
								<span aria-hidden="true" className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200" />
							</button>
						</li>
						<li className="py-4 flex items-center justify-between">
							<div className="flex flex-col">
								<p className="text-sm font-medium text-gray-900" id="privacy-option-2-label">
									Newsletters
								</p>
								<p className="text-sm text-gray-500" id="privacy-option-2-description">
									Health and fitness related news, tips, and advice
								</p>
							</div>
							{/* Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" */}
							<button type="button" className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500" role="switch" aria-checked="false" aria-labelledby="privacy-option-2-label" aria-describedby="privacy-option-2-description">
								{/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
								<span aria-hidden="true" className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200" />
							</button>
						</li>

					</ul>
				</div>
				<div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
					<button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						Save
					</button>
				</div>
			</div>
		</div>

	);
}
