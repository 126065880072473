import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"

export function SuccessBox(props: { error: string | null }) {

	const [success, setSuccess] = useState(props.error);
	useEffect(() => setSuccess(props.error), [props]);

	if (!success) {
		return (
			<div>

			</div>
		)
	}

	return (
		<div className="flex items-center bg-green-500 text-white text-sm font-bold px-4 py-3 mb-5" role="alert">
			<FontAwesomeIcon icon={['far', 'info-circle']} className="text-3xl mr-3" />
			<p>{success}</p>
		</div>
	)
}
