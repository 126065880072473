
export function Homepage() {

	return (

		<div>
			<div id="__next">
				<header>
					<nav>
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between py-8">
							<div className="relative z-10 flex items-center gap-16">
								<a aria-label="Home" href="/">
									<img className="max-h-20" src="/images/logo.png" alt="Logo"></img>
								</a>
								<div className="hidden lg:flex lg:gap-10">

								</div>
							</div>
							<div className="flex items-center gap-6">
								<a className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 block" href="/login">Log in</a>
								<a className="inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 block" href="/signup">Sign Up</a>
							</div>
						</div>
					</nav>
				</header>
				<main>
					<div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
								<div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
									<h1 className="text-4xl font-medium tracking-tight text-gray-900">Manage your Health and Fitness</h1>
									<p className="mt-6 text-lg text-gray-600">Mascot is a new service that lets you manage your health and fitness. Log your weight and track your daily water intake.</p>
									<div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">

									</div>
								</div>
								<div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
									<div className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0">
										<svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-slow">
											<path d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z" stroke="#D4D4D4" strokeOpacity="0.7">
											</path>
											<path d="M513 1025C230.23 1025 1 795.77 1 513" stroke="url(#:R65m:-gradient-1)" strokeLinecap="round">
											</path>
											<defs>
												<linearGradient id=":R65m:-gradient-1" x1={1} y1={513} x2={1} y2={1025} gradientUnits="userSpaceOnUse">
													<stop stopColor="#06b6d4">
													</stop>
													<stop offset={1} stopColor="#06b6d4" stopOpacity={0}>
													</stop>
												</linearGradient>
											</defs>
										</svg>
										<svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-reverse-slower">
											<path d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z" stroke="#D4D4D4" strokeOpacity="0.7">
											</path>
											<path d="M913 513c0 220.914-179.086 400-400 400" stroke="url(#:R65m:-gradient-2)" strokeLinecap="round">
											</path>
											<defs>
												<linearGradient id=":R65m:-gradient-2" x1={913} y1={513} x2={913} y2={913} gradientUnits="userSpaceOnUse">
													<stop stopColor="#06b6d4">
													</stop>
													<stop offset={1} stopColor="#06b6d4" stopOpacity={0}>
													</stop>
												</linearGradient>
											</defs>
										</svg>
									</div>
									<div className="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-top-10 lg:-bottom-20 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
										<img alt="" src="/images/screenshot.png" decoding="async" data-nimg={1} className="pointer-events-none absolute inset-0 h-full w-full" style={{ color: 'transparent' }} />

									</div>
								</div>

							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	)
}
