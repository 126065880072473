import { SettingsCloseAccount } from "./SettingsCloseAccount";
import { SettingsPassword } from "./SettingsPassword";
import { SettingsProfile } from "./SettingsProfile";

export function Settings() {

	return (
		<div className="p-10">

			<div>
				<SettingsProfile></SettingsProfile>
			</div>
			<div className="mt-8">
				<SettingsPassword></SettingsPassword>
			</div>
			<div className="mt-8">
				<SettingsCloseAccount></SettingsCloseAccount>
			</div>
		</div>


	);
}
