import { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import * as ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Water } from 'models/water';
import { Weight } from 'models/weight';
import { generateDateRange, getDayRange } from 'helpers/date';
import { isSameDay } from 'date-fns';

interface HeatmapData {
	date: Date;
	hasWeight: boolean;
	water: number;
}

export function ActivityGraph(props: { weights: Weight[], waters: Water[] }) {

	const [range] = useState(getDayRange(90));
	const [heatmapData, setHeatmapData] = useState<HeatmapData[]>([]);

	useEffect(() => {

		console.log(props.weights)
		const heatmapData = generateDateRange(range, (date: Date) => {

			// console.log(date);

			const hasWeight = props.weights.some(w => isSameDay(w.date, date));
			const water = props.waters.find(w => isSameDay(w.date, date));

			return { date, hasWeight, water: water === undefined ? 0 : water.amount }
		});

		setHeatmapData(heatmapData);

	}, [props])

	useEffect(() => {
		ReactTooltip.rebuild();
	}, [props]);

	return (

		<div className="border-b h-full border-gray-200 bg-white p-5">
			<h3 className="text-lg font-medium leading-6 text-gray-900">Check-Ins</h3>
			<div className="h-full pb-8">
				<CalendarHeatmap
					startDate={range.start}
					endDate={range.end}
					tooltipDataAttrs={(value: HeatmapData) => {
						return {
							'data-html': true,
							'data-tip': ReactDOMServer.renderToString(
								<div className="grid grid-cols-4 gap-4">
									<div>Weight</div>
									{value.hasWeight && <div><FontAwesomeIcon icon={['far', 'weight']} className="" /></div>}
									<div>Water</div>
									{value.water > 0 && <div><FontAwesomeIcon icon={['fas', 'glass']} className="" /></div>}
								</div>
							)
						}
					}}
					values={heatmapData}
					classForValue={(value: HeatmapData) => {

						// console.log(value);
						if (!value) {
							return 'no-data';
						}

						if (value.hasWeight && value.water > 0) {
							return `color-scale-2`;
						}

						if (value.hasWeight || value.water > 0) {
							return `color-scale-1`;
						}

						return 'no-data'
					}}
				/>
			</div>

		</div>
	)

}
