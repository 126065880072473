import { eachDayOfInterval, format, formatDistanceToNow, getISOWeek, isAfter, isSameDay, isToday, parseISO, startOfDay, startOfToday, subDays, subYears } from "date-fns";
import { DateRange } from "../models/date-range";

export function formatLongDate(date: Date) {
	return format(date, 'EEEE, LLLL do, yyyy')
}

export function formatIsoDate(date: Date) {
	return format(date, 'yyyy-MM-dd')
}

export function parseIsoDate(date: string) {
	return parseISO(date);
}

export function timeago(date: Date) {

	if (isToday(date)) {
		return 'Today';
	}

	return `${formatDistanceToNow(date)} ago`;
}

export function getToday() {
	return startOfToday();
}

export function getTodayAsIsoDate() {
	return formatIsoDate(getToday());
}

export function getDaysAgo(days: number) {
	console.log('getting days ago')
	return subDays(startOfToday(), days);
}

export function getYearsAgo(years: number) {
	return subYears(startOfToday(), years);
}

export function getDayRange(days: number) {
	return {
		start: getDaysAgo(days),
		end: getToday(),
	}
}

export function isAfterToday(date: Date) {

	const startOfDate = startOfDay(date);

	return isSameDay(startOfDate, getToday()) || isAfter(startOfDate, getToday());
}

export function isSameOrAfterDate(date: Date, dateToCompare: Date) {
	return isAfter(startOfDay(date), startOfDay(dateToCompare));
}


export function generateDateRange<T>(range: DateRange, callback: (c: Date) => T): T[] {
	return eachDayOfInterval({
		start: range.start, end: range.end
	}).map(callback);
}

export function getWeek(date: Date) {
	return getISOWeek(date);
}
